<script>
import {
  CdrText,
  CdrBreadcrumb,
} from '@rei/cedar';

import { defineAsyncComponent } from 'vue';

import { sendClickAnalytics, sendViewAnalytics, getClientPageData } from '../../../utils';

export default {
  name: 'ContactUs',
  components: {
    CdrText,
    CdrBreadcrumb,
    CUContentComponent: defineAsyncComponent(() => import('../../../components/contactUs/CUContentComponent.vue')),
    CUPopularTopics: defineAsyncComponent(() => import('../../../components/contactUs/CUPopularTopics.vue')),
  },
  props: {
    showHeadLink: { type: Boolean, default: true },
    retailStoreHoursEvents: { type: Array, default() { return []; } },
    emailResponseTime: { type: Number, default: 24 },
    helpCenterNumbers: { type: Object, default() { return {}; } },
    popularTopicsLinks: { type: Array, default() { return []; } },
  },
  data() {
    return {
      breadCrumbItems: [
        { item: { url: '/help', name: 'Help Center' } },
      ],
    };
  },
  computed: {
    popularTools() {
      const popular = [
        { url: '/help/returns', tag: 'returns', label: 'Returns & Exchanges' },
        { url: '/OrderTrackingLoginView', tag: 'order_status', label: 'Order Status & Tracking' },
        { url: '/membership/lookup', tag: 'membership', label: 'Membership & Rewards Lookup' },
        { url: '/help/shipping-timelines.html', tag: 'shipping', label: 'Shipping Information' },
        { url: '/help?a=Cancel-My-Order---id--RunjZv_yTdO4Cewe5IZs9g', tag: 'modify_order', label: 'Cancel/Modify Order' },
        { url: '/help', tag: 'help_center', label: 'Help Center' },
      ];
      return popular;
    },
  },
  mounted() {
    const pageAnalyticsData = getClientPageData()?.analytics;
    sendViewAnalytics({ ...pageAnalyticsData, pageName: `${pageAnalyticsData.pageName}-contact-us_experience-B` });
  },
  methods: {
    popularToolsClickHandler(e) {
      e.preventDefault();
      const {
        target: {
          href = '',
          dataset: {
            tag = '',
          },
        },
      } = e;
      const clickData = {
        pageName: 'rei:help',
        linkName: `rei:contact-us_quick-help_${tag}`,
      };
      sendClickAnalytics(clickData);
      window.location = href;
    },
    articleLinksHandler(e) {
      const {
        target: {
          href = '',
          dataset: {
            tag = '',
          },
        },
      } = e;
      const isPhone = href.includes('tel:');

      if (!isPhone) e.preventDefault();

      const clickData = {
        pageName: 'rei:help',
        linkName: `rei:contact-us_${tag}`,
      };
      sendClickAnalytics(clickData);
      if (!isPhone) window.location = href;
    },
    routerLinksHandler(e) {
      e.preventDefault();
      window.location = '/help';
    },
  },
};
</script>
<template>
  <div class="contact-us-page">
    <div
      id="help-center-category-section-header"
      class="article__heading"
    >
      <cdr-breadcrumb
        :truncation-enabled="false"
        :items="breadCrumbItems"
        class="help-center-category-bc"
      />
      <cdr-text
        tag="h1"
        tabindex="0"
        class="help-center-category-heading"
        data-ui="help-center-category-heading"
      >
        Contact Us
      </cdr-text>
    </div>
    <c-u-popular-topics
      class="article__popular-topics"
      :popular-topics-links="popularTopicsLinks"
    />
    <c-u-content-component
      :retail-store-hours-events="retailStoreHoursEvents"
      :email-response-time="emailResponseTime"
      :help-center-numbers="helpCenterNumbers"
      :is-contact-us-page="true"
    />
  </div>
</template>
<style lang="scss">
  @import 'src/main/style/pages/internal/article.scss';
</style>
