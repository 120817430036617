<script>
import {
  CdrBanner,
  CdrBreadcrumb,
  CdrButton,
  CdrContainer,
  CdrLink,
  CdrList,
  CdrText,
  IconInformationFill,
} from '@rei/cedar';

import { defineAsyncComponent } from 'vue';
import GladlySearch from '../../../components/helpCenter/GladlySearch.vue';
import LeftHandNavigationComponent from '../../../components/helpCenter/LeftHandNavigation.vue';
import { camelCase, snakeCase } from '../../../common/utils';
import { sendClickAnalytics } from '../../../utils';

export default {
  name: 'CategoriesComponent',
  components: {
    HelpErrorPage: defineAsyncComponent(() => import('../../../components/helpCenter/HelpErrorPage.vue')),
    LeftHandNavigationComponent,
    GladlySearch,
    QuickToolsComponent: defineAsyncComponent(() => import('../../../components/helpCenter/QuickToolsComponent.vue')),
    CatPopularLinksComponent: defineAsyncComponent(() => import('../../../components/helpCenter/CatPopularLinksComponent.vue')),
    CdrText,
    CdrBreadcrumb,
    CdrButton,
    CdrLink,
    CdrContainer,
    CdrBanner,
    IconInformationFill,
    CdrList,
  },
  props: {
    helpCenterArray: { type: Array, default() { return []; } },
    helpCenterName: { type: String, default: '' },
    selectedCategory: { type: String, default: '' },
    gladlyPropertyMap: { type: Object, default() { return {}; } },
    gladlyArticlesRedirect: { type: Object, default() { return {}; } },
    helpCenterCDN: { type: Object, default() { return {}; } },
    gladlyAnswersList: { type: Array, default() { return []; } },
    articlesRedirect: { type: Object, default() { return {}; } },
    showLeftHandNavigation: { type: Boolean, default: false },
    showQuickTools: { type: Boolean, default: false },
    quickTools: { type: Array, default() { return []; } },
    relatedLinksConfig: { type: Object, default() { return {}; } },
    catPopularLinks: { type: Array, default() { return []; } },
    articleRedirectConfig: { type: Array, default() { return []; } },
    maxCatPopularLinks: { type: Number, default: 0 },
    gladlyNewHelpCenters: { type: Array, default() { return []; } },
    hasSecondaryHC: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedHelpCenter: null,
      selectedCategoryDetails: null,
      hcSections: this.helpCenterCDN?.sections || [],
      hcSectionsHeaders: [],
      breadCrumbItems: [{
        item: {
          url: '/help',
          name: 'Help Center',
        },
      }],
      showSkeleton: false,
    };
  },
  created() {
    this.selectedHelpCenter = this.hasSecondaryHC
      ? this.gladlyNewHelpCenters.find((hc) => camelCase(hc.helpCenterName) === this.helpCenterName)
      : this.helpCenterArray.find((hc) => camelCase(hc.helpCenterName) === this.helpCenterName);

    this.hcSectionsHeaders = this.getHcSectionsHeaders();
    this.fetchCategories();
  },
  mounted() {
    if (this.hasSecondaryHC) {
      const getMainHCDetails = this.helpCenterArray.find((hc) => camelCase(hc.helpCenterName) === this.selectedHelpCenter?.mainHelpCenter);
      this.pushToBreadCrumbz(
        getMainHCDetails
          ? `/help/helpCenters?hc=${camelCase(getMainHCDetails?.helpCenterName) || ''}` : '',
        (getMainHCDetails?.helpCenterName) || '',
      );

      // window.location should be inside of mounted() otherwise SSR will fail. See
      // https://vuejs.org/guide/scaling-up/ssr.html#access-to-platform-specific-apis
      const currentUrl = window.location.href;
      if (!currentUrl.includes('categories')) {
        this.pushToBreadCrumbz(
          this.selectedCategory
            ? `/help/categories?hc=${camelCase(this.selectedHelpCenter?.mainHelpCenter) || ''}&secHc=${camelCase(this.selectedHelpCenter?.helpCenterName) || ''}`
            : '',
          (this.selectedHelpCenter?.helpCenterName) || '',
        );
      }
    } else {
      this.pushToBreadCrumbz(
        this.selectedCategory
          ? `/help/categories?hc=${camelCase(this.selectedHelpCenter?.helpCenterName) || ''}`
          : '',
        (this.selectedHelpCenter?.helpCenterName) || '',
      );
    }
  },
  methods: {
    pushToBreadCrumbz(url, name) {
      this.breadCrumbItems.push({
        item: {
          url,
          name,
        },
      });
    },
    getHcSectionsHeaders() {
      const {
        helpCenterCDN: {
          sections,
        },
      } = this;
      return sections.map((section) => (section.header));
    },
    fetchCategories() {
      if ((this.hcSections && this.hcSections.length > 0) && this.selectedCategory) {
        if (this.hasSecondaryHC && this.selectedCategory === 'seeAll') {
          const categoryDetails = {};
          categoryDetails.header = `All help content ${this.selectedHelpCenter
            ? `for ${this.selectedHelpCenter?.helpCenterName}` : ''}`;
          categoryDetails.id = '';
          categoryDetails.answerIds = this.hcSections.map(((section) => section.answerIds)).flat(1);
          categoryDetails.answers = this.hcSections.map(((section) => section.answers)).flat(1);
          this.selectedCategoryDetails = categoryDetails;
        } else {
          this.selectedCategoryDetails = this.hcSections.find((hc) => {
            if (this.selectedCategory === 'seeAll') {
              return hc.header.includes('All help content');
            }
            return camelCase(hc.header) === this.selectedCategory;
          });
        }
        this.showSkeleton = false;
      }
      if (this.hcSections && this.hcSections.length > 0) {
        this.hcSections = this.hcSections.filter((obj) => !obj.header.includes('All help content'));
        const results = [];
        while (this.hcSections.length) {
          results.push(this.hcSections.splice(0, 3));
        }
        this.hcSections = results;
      } else {
        this.hcSections = [];
      }
    },
    categoriesMetricHandler(category) {
      const { adobeName, helpCenterName, mainHelpCenter } = this.selectedHelpCenter;
      let linkName = '';
      if (this.hasSecondaryHC) {
        linkName = `${snakeCase(mainHelpCenter)}_${camelCase(helpCenterName)}`;
      } else {
        linkName = adobeName;
      }
      const clickData = {
        pageName: this.pageName,
        linkName: `REI:Help_Center_${linkName}_${camelCase(category)}`,
      };
      sendClickAnalytics(clickData);
      const checkForDirectRedirectURL = this.gladlyArticlesRedirect[category];
      const checkForOneArticle = this.redirectForOneArticle(category);
      const articleURL = this.hasSecondaryHC
        ? `/help/articles?hc=${mainHelpCenter}&secHc=${camelCase(helpCenterName)}&ctgry=${camelCase(category)}`
        : `/help/articles?hc=${camelCase(helpCenterName)}&ctgry=${camelCase(category)}`;
      window.location = checkForDirectRedirectURL
          || checkForOneArticle
          || articleURL;
    },
    redirectForOneArticle(category) {
      let selectedCat = this.hcSections?.length
          && this.hcSections.map((hcSections) => hcSections.find((hc) => camelCase(hc.header) === camelCase(category)));
      selectedCat = selectedCat?.find((cat) => !!cat);
      const answerIds = selectedCat?.answerIds;
      return (answerIds && (answerIds.length === 1))
        ? `/help?a=${this.replaceCharsFunction((selectedCat?.header) || '')}---id--${answerIds[0]}` : null;
    },
    articlesMetricHandler(article) {
      const { adobeName, mainHelpCenter, helpCenterName } = this.selectedHelpCenter;
      const { header } = this.selectedCategoryDetails;
      const { name } = article;
      let linkName = '';
      if (this.hasSecondaryHC) {
        linkName = `${snakeCase(mainHelpCenter)}_${camelCase(helpCenterName)}_${camelCase(header)}`;
      } else {
        linkName = `${adobeName}_${camelCase(header)}`;
      }
      const clickData = {
        pageName: this.pageName,
        linkName: `REI:Help_Center_${linkName}_${camelCase(name)}`,
      };
      sendClickAnalytics(clickData);
    },
    getArticlesHref(article) {
      const { helpCenterName } = this.selectedHelpCenter;
      const { header } = this.selectedCategoryDetails;
      const { name, id } = article;
      const articleKey = Object.keys(this.articlesRedirect).find((key) => {
        const splitKeys = key.split('|');
        if (splitKeys[0] === helpCenterName
            && ((splitKeys[1] === header) || header.includes('All help content'))
            && splitKeys[2] === name) {
          return key;
        }
        return null;
      });
      const checkForArticleRedirect = articleKey && this.articlesRedirect[articleKey];
      return checkForArticleRedirect || `/help?a=${this.replaceCharsFunction(name)}---id--${id}`;
    },
    replaceCharsFunction(value) {
      const replaceChars = {
        '&': 'and',
        ' ': '-',
        '/': '',
      };
      return value.replace(/[& /]/g, (m) => replaceChars[m]);
    },
    checkForCategoriesClass(i) {
      if (this.hasSecondaryHC) {
        return '';
      }
      return i === this.hcSectionsHeaders.length - 1 ? 'hide' : '';
    },
  },
};

</script>
<template>
  <div>
    <div
      id="help-center-category"
      class="help-center-category"
    >
      <div
        id="help-center-category-section-header"
        class="help-center-category-section-header"
      >
        <cdr-breadcrumb
          v-if="(hcSections && hcSections.length > 0)"
          :truncation-enabled="false"
          :items="breadCrumbItems"
          class="help-center-category-bc"
        />
        <cdr-text
          tag="h1"
          tabindex="0"
          class="help-center-category-heading"
          data-ui="help-center-category-heading"
        >
          {{ (selectedHelpCenter && selectedHelpCenter.helpCenterName) || '' }}
        </cdr-text>
      </div>
      <gladly-search
        gladly-id="help-center-category-gladly-articles"
        gladly-class="help-center-category-gladly-articles"
        :gladly-property-map="gladlyPropertyMap"
        :show-left-hand-navigation="showLeftHandNavigation"
        :help-center-names="helpCenterArray"
        from="Category"
        :related-links-config="relatedLinksConfig"
        :gladly-answers-list="gladlyAnswersList"
        :help-center-c-d-n-response="helpCenterCDN"
        :selected-category="selectedCategory"
        :gladly-new-help-centers="gladlyNewHelpCenters"
        :article-redirect-config="articleRedirectConfig"
      />
      <quick-tools-component
        v-if="showQuickTools && (quickTools && quickTools.length)"
        :quick-tools="quickTools"
        :from="true"
        :adobe-tag="(selectedHelpCenter && selectedHelpCenter.helpCenterName) || ''"
      />
      <cat-popular-links-component
        v-if="(catPopularLinks && catPopularLinks.length > 0) && !selectedCategoryDetails"
        :cat-popular-links="catPopularLinks"
        :selected-help-center="selectedHelpCenter"
        :max-cat-popular-links="maxCatPopularLinks"
      />
      <div
        v-if="!selectedCategoryDetails && (hcSections && hcSections.length > 0)"
        id="help-center-category-section"
        class="help-center-category-section"
      >
        <cdr-text
          tag="h2"
          tabindex="0"
          class="help-center-category-section-heading"
        >
          All Topics
        </cdr-text>
        <div class="help-center-category-wrapper">
          <div
            v-for="(header, i) in hcSectionsHeaders"
            :key="i"
            class="help-center-category-row"
          >
            <div
              :key="i"
              :class="['help-center-category-column', checkForCategoriesClass(i)]"
              :data-ui="header"
            >
              <cdr-button
                class="help-center-category-button"
                modifier="secondary"
                @click="categoriesMetricHandler(header)"
              >
                {{ header }}
              </cdr-button>
            </div>
          </div>
        </div>
        <cdr-link
          modifier="standalone"
          class="help-center-category-seeAll-link"
          @click="categoriesMetricHandler('See all')"
        >
          See all in {{ (selectedHelpCenter && selectedHelpCenter.helpCenterName.toLowerCase()) || '' }}
        </cdr-link>
      </div>
      <help-error-page v-if="hcSections && (hcSections.length === 0)" />
    </div>
    <cdr-container
      v-if="!!selectedCategoryDetails"
      id="help-center-articles"
      class="help-center-articles"
    >
      <div class="help-center-articles-nav-main">
        <div
          v-if="!showSkeleton"
          class="help-center-articles-main-articles"
        >
          <cdr-text
            tag="h1"
            tabindex="0"
            class="help-center-articles-heading"
            data-ui="help-center-articles-heading"
          >
            {{ (selectedCategoryDetails && selectedCategoryDetails.header) || '' }}
          </cdr-text>
          <cdr-list
            class="help-center-articles-ul"
            modifier="unordered"
          >
            <li
              v-for="(answer, i) in selectedCategoryDetails.answers"
              :key="i"
            >
              <cdr-link
                :href="getArticlesHref(answer)"
                modifier="standalone"
                class="help-center-articles-link"
                @click="articlesMetricHandler(answer)"
              >
                {{ answer.name }}
              </cdr-link>
            </li>
          </cdr-list>
          <cdr-banner
            v-if="selectedCategoryDetails && selectedCategoryDetails.answers
              && selectedCategoryDetails.answers.length === 0"
            class="help-center-articles-banner"
            tabindex="0"
            type="info"
          >
            <template #icon-left>
              <icon-information-fill />
            </template>
            <cdr-text
              tag="p"
              class="help-center-articles-banner-text"
              data-ui="rei-outlet-msg"
            >
              Currently, no Articles found for the selected category. Please try again by refreshing the page or use our search feature. You can also contact
              us for help.
            </cdr-text>
          </cdr-banner>
        </div>
        <left-hand-navigation-component
          v-if="showLeftHandNavigation && selectedCategory"
          :universal-h-c-sections="helpCenterArray"
          navigation-class="help-center-left-navigation-article"
          :gladly-new-help-centers="gladlyNewHelpCenters"
        />
      </div>
    </cdr-container>
  </div>
</template>
